/**
 * 라우팅 정보를 처리하는 컴포넌트
 *
 */
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";

import { IError, IUser } from "@homexjang/datasources/types";
import { routes, privateRoutes } from "../config/routes";
import { SessionContext, SessionContextProvider } from "../context";
import { Layout } from "../layout";
import { Loading, NotFound } from "../components";

interface RouterProps {
  loading: boolean;
  errors: IError[] | undefined;
  me: IUser | undefined;
  onSignOut: () => void;
}

interface PrivateRouteProps {
  path: string;
  exact: boolean;
  component: React.ComponentType<RouteProps>;
}

/**
 * 로그인 회원만 접속할 수 있는 Route를 처리하는 컴포넌트
 *
 * @param param0
 * @returns
 */
const PrivateRoute = ({
  exact,
  path,
  component: Component,
  ...rest
}: PrivateRouteProps) => (
  <SessionContext.Consumer>
    {(sessionContext) => (
      <Route
        exact={exact}
        path={path}
        {...rest}
        render={({ location }) => {
          return sessionContext.isAuthenticated() ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: "/sign-in",
                state: { from: location },
              }}
            />
          );
        }}
      />
    )}
  </SessionContext.Consumer>
);

const MyRouter = ({ loading, me, onSignOut }: RouterProps) => {
  return (
    <SessionContextProvider me={me} onSignOut={onSignOut}>
      {loading ? (
        <Loading />
      ) : (
        <Router>
          <Layout>
            <Switch>
              {routes.map(({ path, exact, Component }) => (
                <Route key={path} exact={exact} path={path}>
                  <Component />
                </Route>
              ))}
              {/* <Route>
                <Switch>
                  {privateRoutes.map(({ path, exact, Component }) => (
                    <PrivateRoute
                      key={path}
                      exact={exact}
                      path={path}
                      component={Component}
                    />
                  ))}
                </Switch>
              </Route> */}
              {privateRoutes.map(({ path, exact, Component }) => (
                <PrivateRoute
                  key={path}
                  exact={exact}
                  path={path}
                  component={Component}
                />
              ))}
              <Route>
                <Route path={"*"} component={NotFound} />
              </Route>
            </Switch>
          </Layout>
        </Router>
      )}
    </SessionContextProvider>
  );
};

export default MyRouter;
