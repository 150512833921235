import { MenuDummy } from "@homexjang/datasources/types";
import { t } from "../lib";

const menu: MenuDummy[] = [
  {
    type: "list",
    list: [
      {
        title: t("Dashboard"),
        path: "/admin",
        icon: "dashboard",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "서비스",
        children: [
          {
            title: "전광판",
            path: "/billBoards",
            icon: "newspaper",
          },
          {
            title: "FAQ",
            path: "/faq",
            icon: "person",
          },
          {
            title: "공지사항",
            path: "/notice",
            icon: "person",
          },
          {
            title: "웹진",

            icon: "person",
          },
          {
            title: "배너",

            icon: "person",
          },
        ],
        icon: "person",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: t("User"),
        children: [
          {
            title: t("User"),
            path: "/users",
            icon: "person",
          },
          {
            title: "운동기록",

            icon: "person",
          },
          {
            title: "운동리스트",

            icon: "person",
          },
          {
            title: "마일리지",

            icon: "person",
          },
          {
            title: "결제",

            icon: "person",
          },
        ],
        icon: "person",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: "운동",
        children: [
          {
            title: "운동",
            path: "/exercise",
            icon: "fitness_center",
          },
          {
            title: "코치",
            path: "/coach",
            icon: "boy",
          },
          {
            title: "코치+운동",
            path: "/coachExec",
            icon: "directions_run",
          },
          {
            title: "영상",
            path: "/execClip",
            icon: "movie",
          },
          {
            title: "리스트",
            path: "/execPlaylist",
            icon: "playlist_play",
          },
        ],
        icon: "person",
      },
    ],
    roles: ["ROLE_ROOT", "ROLE_STAFF", "ROLE_PARTNER"],
  },
  {
    type: "divider",
  },
  {
    type: "list",
    list: [
      {
        title: t("System"),
        children: [
          {
            title: t("SystemSettings"),
            path: "/system",
            icon: "settings",
          },
          {
            title: t("Batch"),
            path: "/batch",
            icon: "settings",
          },

          {
            title: t("Role"),
            path: "/roles",
            icon: "lock",
          },
          {
            title: t("Staff"),
            path: "/staves",
            icon: "person",
          },
          {
            title: t("AppInfo"),
            path: "/about",
            icon: "info",
          },
        ],

        icon: "folder",
      },
    ],
    roles: ["ROLE_ROOT"],
  },
  {
    type: "divider",
  },
];

export default menu;
