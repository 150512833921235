import React from "react";
import { Typography } from "@mui/material";

import {
  BOARD_LIST,
  BOARD_VIEW,
  BOARD_CREATE,
  BOARD_UPDATE,
  BOARD_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "title", label: "Title" },
    { id: "content", label: "Content" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "Board",
    crud: {
      c: BOARD_CREATE,
      r: BOARD_VIEW,
      u: BOARD_UPDATE,
      d: BOARD_DELETE,
    },
    columns: [
      { id: "type", label: "Type", type: "fix", value: "NOTICE" },
      { id: "title", label: "Title", type: "text" },
      { id: "content", label: "Content" },
      { id: "use", label: "Use", type: "boolean" },
    ],
  };

  return (
    <Page
      title={t("Board")}
      pageTitle={
        <Typography variant="h4">
          {t("Board")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ boardList: BOARD_LIST, param: { type: "NOTICE" } }}
      />
    </Page>
  );
};

export default List;
