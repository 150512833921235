import React from "react";
import { Typography } from "@mui/material";

import {
  COACH_LIST,
  COACH_VIEW,
  COACH_CREATE,
  COACH_UPDATE,
  COACH_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "_id", label: "ID" },
    { id: "name", label: "Name" },
    { id: "nick", label: "Nick" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "coach",
    crud: {
      c: COACH_CREATE,
      r: COACH_VIEW,
      u: COACH_UPDATE,
      d: COACH_DELETE,
    },
    columns: [
      { id: "_id", label: "ID" },
      { id: "name", label: "name" },
      { id: "nick", label: "Nick" },
      { id: "use", label: "Use", format: "Boolean" },
      { id: "description", label: "Description" },
    ],
    excel: true,
  };

  return (
    <Page
      title={t("Coach")}
      pageTitle={
        <Typography variant="h4">
          {t("Coach")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table headers={headers} form={form} query={{ coachList: COACH_LIST }} />
    </Page>
  );
};

export default List;
