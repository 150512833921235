import React from "react";
import { Typography } from "@mui/material";

import {
  COACHEXEC_LIST,
  COACHEXEC_VIEW,
  COACHEXEC_CREATE,
  COACHEXEC_UPDATE,
  COACHEXEC_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "_id", label: "ID" },
    { id: "coach._id", label: "Name" },
    { id: "exercise._id", label: "Nick" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "coachExec",
    crud: {
      c: COACHEXEC_CREATE,
      r: COACHEXEC_VIEW,
      u: COACHEXEC_UPDATE,
      d: COACHEXEC_DELETE,
    },
    columns: [
      { id: "_id", label: "ID" },
      { id: "coach._id", label: "Name" },
      { id: "exercise._id", label: "Nick" },
      { id: "use", label: "Use", format: "Boolean" },
    ],
    excel: true,
  };

  return (
    <Page
      title={t("CoachExec")}
      pageTitle={
        <Typography variant="h4">
          {t("CoachExec")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ coachExecList: COACHEXEC_LIST }}
      />
    </Page>
  );
};

export default List;
