import { gql } from '@apollo/client';

export const USER_LIST = gql`
  query UserList(
    $searchText: String
    $skip: Int
    $limit: Int
    $pointSort: String
    $sort: String
    $email: String
    $phone: String
  ) {
    userList(
      searchText: $searchText
      skip: $skip
      limit: $limit
      pointSort: $pointSort
      sort: $sort
      email: $email
      phone: $phone
    ) {
      _id
      totalCount
      list {
        _id
        nickName
        emails {
          address
          verified
        }
        status
        general {
          firstName
          lastName
          name
          phone
        }
        point
        referer
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_VIEW = gql`
  query UserView($id: ID) {
    userView(id: $id) {
      _id
      nickName
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
        name
        phone
        juminNumber
        bank {
          account
          name
          holder
        }
      }
      roles {
        _id
        name
        desc
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_NEW = gql`
  mutation UserNew($input: UserInput) {
    userNew(input: $input) {
      _id
      emails {
        address
        verified
      }
      general {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UserUpdate($id: ID, $input: UserUpdateInput) {
    userUpdate(id: $id, input: $input) {
      _id
      emails {
        address
        verified
      }
      general {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_REMOVE = gql`
  mutation UserRemove($id: ID) {
    userRemove(id: $id)
  }
`;
