import { gql } from "@apollo/client";

const EXECPLAYLIST_LIST = gql`
  query ExecPlaylistList($searchText: String, $skip: Int, $limit: Int) {
    execPlaylistList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        type
        level
        partA
        partB
        partC
        nameKor
        nameEng
        use
        description
        createdAt
        updatedAt
        updatedUser {
          _id
        }
      }
    }
  }
`;

const EXECPLAYLIST_VIEW = gql`
  query ExecPlaylistView($id: ID) {
    execPlaylistView(id: $id) {
      _id
      type
      repeat
      level
      partA
      partB
      partC
      nameKor
      nameEng
      tags
      thumbNail
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const EXECPLAYLIST_CREATE = gql`
  mutation ExecPlaylistCreate($input: execPlaylistInput) {
    execPlaylistCreate(input: $input) {
      _id
      type
      title
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const EXECPLAYLIST_UPDATE = gql`
  mutation ExecPlaylistUpdate($id: ID, $input: execPlaylistInput) {
    execPlaylistUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const EXECPLAYLIST_DELETE = gql`
  mutation ExecPlaylistDelete($id: ID) {
    execPlaylistDelete(id: $id)
  }
`;

export {
  EXECPLAYLIST_LIST,
  EXECPLAYLIST_VIEW,
  EXECPLAYLIST_CREATE,
  EXECPLAYLIST_UPDATE,
  EXECPLAYLIST_DELETE,
};
