import { gql } from "@apollo/client";

const BATCH_LIST = gql`
  query BatchList($searchText: String, $skip: Int, $limit: Int) {
    batchList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
      }
    }
  }
`;

const BATCH_VIEW = gql`
  query BatchView($id: ID) {
    batchView(id: $id) {
      _id
    }
  }
`;

const BATCH_RUN = gql`
  mutation BatchRun($input: BatchInput) {
    batchRun(input: $input) {
      _id
    }
  }
`;

const BATCH_NEW = gql`
  mutation BatchNew($input: BatchInput) {
    batchNew(input: $input) {
      _id
      name
      desc
      createdAt
      updatedAt
    }
  }
`;

const BATCH_UPDATE = gql`
  mutation BatchUpdate($id: ID, $input: BatchInput) {
    batchUpdate(id: $id, input: $input) {
      _id
      name
      desc
      createdAt
      updatedAt
    }
  }
`;

const BATCH_REMOVE = gql`
  mutation BatchRemove($id: ID) {
    batchRemove(id: $id)
  }
`;

export {
  BATCH_LIST,
  BATCH_VIEW,
  BATCH_RUN,
  BATCH_NEW,
  BATCH_UPDATE,
  BATCH_REMOVE,
};
