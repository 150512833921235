import React from "react";
import { Typography } from "@mui/material";

import {
  EXERCISE_LIST,
  EXERCISE_VIEW,
  EXERCISE_CREATE,
  EXERCISE_UPDATE,
  EXERCISE_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "_id", label: "ID" },
    { id: "type", label: "Type" },
    { id: "level", label: "Level" },
    { id: "partA", label: "PartA" },
    { id: "partB", label: "PartB" },
    { id: "partC", label: "PartC" },
    { id: "pose", label: "Pose" },
    { id: "nameKor", label: "Name" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "exercise",
    crud: {
      c: EXERCISE_CREATE,
      r: EXERCISE_VIEW,
      u: EXERCISE_UPDATE,
      d: EXERCISE_DELETE,
    },
    columns: [
      { id: "type", label: "Type" },
      { id: "tool", label: "Tool" },
      { id: "repeatUnit", label: "RepeatUnit" },
      { id: "level", label: "Level", format: "Number" },
      { id: "partA", label: "PartA" },
      { id: "partB", label: "PartB" },
      { id: "partC", label: "PartC" },
      { id: "pose", label: "Pose" },
      { id: "nameKor", label: "NameKor" },
      { id: "nameEng", label: "NameEng" },
      { id: "tags", label: "Tags" },
      { id: "thumbNail", label: "ThumbNail" },
      { id: "use", label: "Use", format: "Boolean" },
      { id: "description", label: "Description" },
    ],
    excel: true,
  };

  return (
    <Page
      title={t("Exercise")}
      pageTitle={
        <Typography variant="h4">
          {t("Exercise")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ exerciseList: EXERCISE_LIST }}
      />
    </Page>
  );
};

export default List;
