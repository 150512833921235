import React from "react";
import { Typography } from "@mui/material";

import {
  BILLBOARD_LIST,
  BILLBOARD_VIEW,
  BILLBOARD_CREATE,
  BILLBOARD_UPDATE,
  BILLBOARD_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";
import { BillBoard } from "@homexjang/datasources/types";

const List = () => {
  const headers = [
    { id: "type", label: "Type" },
    { id: "title", label: "Title" },
    { id: "thumbNail", label: "ThumbNail", width: 100 },
    { id: "link", label: "Link" },
    { id: "description", label: "Description" },
    { id: "use", label: "Use" },
  ];

  //TODO: use toggle button
  const form = {
    name: "Billboard",
    crud: {
      c: BILLBOARD_CREATE,
      r: BILLBOARD_VIEW,
      u: BILLBOARD_UPDATE,
      d: BILLBOARD_DELETE,
    },
    columns: [
      { id: "type", label: "Type", type: "select", enum: BillBoard },
      { id: "title", label: "Title" },
      { id: "thumbNail", label: "ThumbNail" },
      { id: "link", label: "Link" },
      { id: "description", label: "Description" },
      { id: "use", label: "Use", format: "Boolean" },
    ],
  };

  return (
    <Page
      title={t("Billboard")}
      pageTitle={
        <Typography variant="h4">
          {t("Billboard")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ billBoardList: BILLBOARD_LIST }}
      />
    </Page>
  );
};

export default List;
