import { gql } from "@apollo/client";

const BOARD_LIST = gql`
  query BoardList($searchText: String, $type: String, $skip: Int, $limit: Int) {
    boardList(
      searchText: $searchText
      type: $type
      skip: $skip
      limit: $limit
    ) {
      _id
      totalCount
      list {
        _id
        type
        title
        content
        answer
        thumbNail
        use
        createdAt
        updatedAt
        updatedUser {
          _id
        }
      }
    }
  }
`;

const BOARD_VIEW = gql`
  query BoardView($id: ID) {
    boardView(id: $id) {
      _id
      type
      title
      content
      answer
      thumbNail
      use
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const BOARD_CREATE = gql`
  mutation BoardCreate($input: BoardInput) {
    boardCreate(input: $input) {
      _id
      type
      title
      content
      answer
      thumbNail
      use
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const BOARD_UPDATE = gql`
  mutation BoardUpdate($id: ID, $input: BoardInput) {
    boardUpdate(id: $id, input: $input)
  }
`;

const BOARD_DELETE = gql`
  mutation BoardDelete($id: ID) {
    boardDelete(id: $id)
  }
`;

export { BOARD_LIST, BOARD_VIEW, BOARD_CREATE, BOARD_UPDATE, BOARD_DELETE };
