import React, { useState } from "react";
import { gql, useQuery, useMutation, ApolloError } from "@apollo/client";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import QueryString from "qs";

import { LIST_ARGS_DEFAULT } from "../../config/constants";
import {
  IListArgs,
  IPagination,
  ICondition,
} from "@homexjang/datasources/types";
import { IHeader, IForm } from "../../types";
import { filterGraphQLErrors } from "../../lib";
import TableBody from "./TableBody";

// import { Theme } from "@mui/material/styles";
// import { makeStyles } from "@mui/styles";

import { t } from "../../lib";
import { Confirm } from "../../components";
import View from "./ViewContainer";
import Upload from "./ExcelImport";

import {
  Divider,
  Icon,
  IconButton,
  TextField,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

export interface Props {
  headers: IHeader[] | undefined;
  form?: IForm | undefined;
  query: any;
  reload?: boolean;
  done?: () => void;
}

// const useStyles = makeStyles((theme: Theme) => ({
//   pagination: {
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     padding: theme.spacing(1),
//   },
// }));

const getQuery = (query: any) => {
  const _temp = Object.keys(query);
  if (_temp.length > 0) {
    query.key = _temp[0];
    query.query = query[query.key];
  }
};

const Container = ({ headers: _headers, form, query, reload, done }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryData: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const page = queryData?.page ? parseInt(queryData?.page) : 1;

  //

  const [listArgs, setListArgs] = useState<IListArgs>(
    queryData?.page
      ? { ...LIST_ARGS_DEFAULT, skip: (page - 1) * 10 }
      : LIST_ARGS_DEFAULT
  );

  const [pointSort, setPointSort] = useState<string>(null);
  const [sort, setSort] = useState<string>("asc");
  const [search, setSearch] = useState<ICondition>(null);
  const [id, setId] = useState<string>(null);
  const [viewType, setViewType] = useState<"c" | "r" | "u">("c");
  const [openView, setOpenView] = useState<boolean | undefined>();
  const [openUpload, setOpenUpload] = useState<boolean | undefined>();
  const [openConfirm, setOpenConfirm] = useState<boolean | undefined>();

  const [pagination, setPagination] = useState<IPagination>({
    rowsPerPageOptions: [10, 25, 50],
    rowsPerPage: 10,
    count: 0,
    page,
  });

  getQuery(query);

  const param = query?.param ? query.param : {};
  const { loading, error, data, refetch } = useQuery(query.query, {
    fetchPolicy: "network-only",
    variables: { ...listArgs, pointSort, sort, search, ...param },
  });

  if (reload) {
    refetch();
    done && done();
  }

  // const errors = filterGraphQLErrors(error);
  // const hasErrors = !!(errors && errors.length > 0);

  let dataList = data ? data[query.key] : {};
  console.log(data, query.key);
  if (!dataList?.totalCount && !dataList?.list && dataList?.length > 0) {
    dataList = {
      totalCount: data[query.key].length,
      list: data[query.key],
    };
  }

  const totalCount = dataList?.totalCount || {};
  // const list = dataList?.list || {};

  // const classes = useStyles();

  const handlePointSort = () => {
    if (pointSort === null || pointSort === "asc") {
      setPointSort("desc");
    } else if (pointSort === "desc") {
      setPointSort("asc");
    }
  };

  const handleSort = () => {
    if (sort === "desc") {
      setSort("asc");
      setPointSort(null);
    } else {
      setSort("desc");
      setPointSort(null);
    }
  };

  const handleSearch = async (id: string, value: string) => {
    if (value === "") {
      await setSearch(_.omit({ ...search }, [id]));
    } else {
      await setSearch({ ...search, [id]: value });
    }

    const qd: any = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    qd.page = 1;
    history.push(`${location.pathname}?${QueryString.stringify(qd)}`);

    const newPagination = { ...pagination, page: 1 };
    setPagination(newPagination);
    handlePaginationChange(newPagination);
  };

  const handlePaginationChange = (pagination: IPagination) => {
    const newArgs = {
      ...listArgs,
      skip: (pagination.page - 1) * pagination.rowsPerPage,
      limit: pagination.rowsPerPage,
    };

    setListArgs(newArgs);
    refetch(newArgs);
  };

  const headers = _.cloneDeep(_headers);
  if (form?.crud?.r) {
    headers.push({
      id: "detail",
      label: "Details",
      type: "action",
      cb: async (_id: string) => {
        await setId(_id);
        await setViewType("r");
        await setOpenView(true);
      },
    });
  }

  const DUMMY = gql`
    mutation RoleUpdate($id: ID, $input: RoleInput) {
      roleUpdate(id: $id, input: $input) {
        _id
        name
        desc
        createdAt
        updatedAt
      }
    }
  `;

  const [remove] = useMutation(form?.crud?.d ? form?.crud?.d : DUMMY, {
    errorPolicy: "all",
    onCompleted: (response) => {
      if (!response?.roleRemove) return;

      console.log("");
    },
    onError: (error: ApolloError) => {
      console.log("roleRemove error", error);
    },
  });

  const handleConfirmRemove = async (answer: boolean) => {
    if (answer && id) {
      await remove({ variables: { id } });
      setOpenConfirm(undefined);
      refetch();
    } else {
      setOpenConfirm(undefined);
    }
  };

  if (form?.crud?.d) {
    headers.push({
      id: "remove",
      label: "Remove",
      type: "action",
      cb: async (_id: string) => {
        await setId(_id);
        await setOpenConfirm(true);
      },
    });
  }

  return (
    <>
      <TableContainer>
        <Toolbar variant="dense" disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {totalCount ? t("TotalCount", { count: totalCount }) : null}
          </Typography>
          {form?.excel && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={async () => {
                await setOpenUpload(true);
              }}
            >
              <Icon>upload_file</Icon>
            </IconButton>
          )}
          {form?.crud?.c && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={async () => {
                await setViewType("c");
                await setOpenView(true);
              }}
            >
              <Icon>add</Icon>
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              {headers &&
                headers.map((header) => {
                  const { id, label, width } = header;
                  if (header.filter)
                    return (
                      <TableCell width={width}>
                        <TextField
                          id={id}
                          value={search && search[id]}
                          label={t(label)}
                          variant="standard"
                          size="small"
                          onChange={(e) => {
                            if (header.param)
                              handleSearch(header.param, e.target.value);
                            else handleSearch(id, e.target.value);
                          }}
                        />
                      </TableCell>
                    );
                  if (header.sort)
                    return <TableCell key={id}>{t(label)}</TableCell>;
                  else return <TableCell key={id}>{t(label)}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody
            loading={loading}
            errors={filterGraphQLErrors(error)}
            headers={headers}
            data={dataList}
            pointSort={pointSort}
            dateSort={sort}
            search={search}
            onPointSort={handlePointSort}
            onSort={handleSort}
            onSearch={handleSearch}
            onPaginationChange={handlePaginationChange}
            onRefetch={() => refetch(listArgs)}
            pagination={pagination}
            setPagination={setPagination}
          />
        </Table>
      </TableContainer>
      {form && (
        <View
          id={id}
          type={viewType}
          form={form}
          refetch={refetch}
          open={!!openView}
          // role={openView}
          onClose={async () => {
            await setId("");
            await setOpenView(false);
          }}
        />
      )}
      {form?.excel && (
        <Upload
          form={form}
          refetch={refetch}
          open={!!openUpload}
          onClose={async () => {
            await setOpenUpload(false);
          }}
        />
      )}

      <Confirm
        open={!!openConfirm}
        title={`${t("Remove")}`}
        message={t("RemoveConfirmMessage")}
        onClose={handleConfirmRemove}
      />
    </>
  );
};

export default Container;
