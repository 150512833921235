import { gql } from "@apollo/client";

const COACH_LIST = gql`
  query CoachList($searchText: String, $skip: Int, $limit: Int) {
    coachList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        name
        nick
        use
      }
    }
  }
`;

const COACH_VIEW = gql`
  query CoachView($id: ID) {
    coachView(id: $id) {
      _id
      name
      nick
      use
    }
  }
`;

const COACH_CREATE = gql`
  mutation CoachCreate($input: coachInput) {
    coachCreate(input: $input) {
      _id
      type
      title
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const COACH_UPDATE = gql`
  mutation CoachUpdate($id: ID, $input: coachInput) {
    coachUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const COACH_DELETE = gql`
  mutation CoachDelete($id: ID) {
    coachDelete(id: $id)
  }
`;

export { COACH_LIST, COACH_VIEW, COACH_CREATE, COACH_UPDATE, COACH_DELETE };
