import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { t } from "../../lib";
import { Modal } from "../../components";
import { IForm } from "../../types";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Form from "./Form";
import { CHECK_UPLOAD, APPLY_UPLOAD } from "../../graphql";

interface Props {
  open: boolean;
  onClose: () => void;
  form?: IForm | undefined;
  refetch?: any;
}

const Container = ({ open, onClose, form, refetch }: Props) => {
  const [input, setInput] = useState<any>({});
  const [apply, setApply] = useState<any>(false);
  const variables = {
    id: input?.file?._id,
    name: form?.name,
    columns: form?.columns,
  };

  const { refetch: checkUpload, data: checkUploadData } = useQuery(
    CHECK_UPLOAD,
    {
      fetchPolicy: "network-only",
      variables,
    }
  );

  const { refetch: applyUpload } = useQuery(APPLY_UPLOAD, {
    fetchPolicy: "network-only",
    variables: {
      ...variables,
      apply,
    },
  });

  useEffect(() => {
    console.log(input?.file?._id);
    if (input?.file?._id) {
      checkUpload();
    }
  }, [input, checkUpload]);

  const uploadForm = {
    columns: [
      { id: "file", label: "File", type: "file" },
      { id: "add", label: "New", type: "label" },
      { id: "remove", label: "Delete", type: "label" },
      { id: "error", label: "Error", type: "label" },
    ],
  };

  const handleInputChange = async (name: string, files: any) => {
    let file = files;
    if (files?.length > 0) {
      file = files[0];
    }

    if (file) {
      await setInput({ ...input, [name]: file });
    }
  };

  console.log("checkUploadData", checkUploadData?.checkUpload.result);

  const handleSubmit = () => {
    const items = checkUploadData?.checkUpload.result;
    if (!items?.length || items?.length === 0) return false;

    setApply(true);
    applyUpload();

    ///applyUploadData
    onClose();
  };

  const errorDetail = (): string => {
    const result: string[] = [];
    const items = checkUploadData?.checkUpload.result;
    if (!items?.length || items?.length === 0) return "";

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item._status === "error") {
        console.log(item);
        if (item._error?.length && item._error.length > 0) {
          item._error.forEach((error: any) => {
            result.push(error);
          });
        }
      }
    }

    return result.join("\r");
  };

  const errorDetails = errorDetail();

  return (
    <Modal
      open={open}
      title={`${t(form.name)} ${t("Upload")}`}
      Actions={
        <>
          <React.Fragment>
            <Button onClick={onClose}>{t("Close")}</Button>
            <LoadingButton fullWidth={false} onClick={handleSubmit}>
              {t("Submit")}
            </LoadingButton>
          </React.Fragment>
        </>
      }
      onClose={onClose}
    >
      <Form
        type={"i"}
        // errors={errors}
        form={uploadForm}
        input={checkUploadData?.checkUpload}
        onInputChange={handleInputChange}
      />
      <textarea
        placeholder="여기에 입력하세요"
        value={errorDetails}
        rows={10}
        cols={66}
      ></textarea>
    </Modal>
  );
};

export default Container;
