/**
 * 회원 세션에 대한 Context 설정을 수행한다.
 *
 */

import React, { createContext, ReactNode, useState } from "react";

import {
  LOCAL_STORAGE_KEY,
  SESSION_CONTEXT_DEFAULT,
} from "../config/constants";
import { ISessionContext, IUser } from "@homexjang/datasources/types";

interface Props {
  me?: IUser;
  onSignOut: () => void;
  children: ReactNode;
}

/**
 * 세션 컨텍스트 생성 및 초기화
 */
export const SessionContext = createContext<ISessionContext>(
  SESSION_CONTEXT_DEFAULT
);

/**
 * 세션 컨텍스트 컴포넌트
 * @param Props
 *    me: 앱 구동 초기에 회원 정보를 서버로부터 읽어 전달한다.
 *
 * @returns
 */
export const SessionContextProvider = ({ me, children, onSignOut }: Props) => {
  const [account, setAccount] = useState<IUser | undefined>();

  const isAuthenticated = () => !!(account || me);

  const signIn = (user: IUser) => {
    const id: string | undefined = user._id?.toString();
    if (id) {
      localStorage.setItem(LOCAL_STORAGE_KEY.SESSION, id);
      if (user.tokens && user.tokens.length === 2) {
        localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, user.tokens[0]);
        localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, user.tokens[1]);
      }
      setAccount(user);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY.SESSION);
    }
  };

  const signOut = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.SESSION);
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    setAccount(undefined);
    onSignOut();
  };

  return (
    <SessionContext.Provider
      value={{
        account: account || me,
        isAuthenticated,
        signIn,
        signOut,
        refresh: () => {},
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
