import Home from "../modules/web/HomeContainer";
import SignIn from "../modules/auth/SignInContainer";

import Dashboard from "../modules/dashboard/DashboardContainer";
import BillBoardList from "../modules/list/BillBoardList";
import BoardFAQList from "../modules/list/BoardFAQList";
import BoardNoticeList from "../modules/list/BoardNoticeList";
import exerciseList from "../modules/list/ExerciseList";
import coachList from "../modules/list/CoachList";
import coachExecList from "../modules/list/CoachExecList";
import execClipList from "../modules/list/ExecClipList";
import ExecPlaylistList from "../modules/list/ExecPlaylistList";
import Batch from "../modules/list/BatchList";
import RoleList from "../modules/list/RoleList";
import UserList from "../modules/list/UserList";

import VerifyUser from "../modules/auth/VerifyUserContainer";
import ResetPassword from "../modules/auth/ResetPasswordContainer";

export const routes = [
  { path: "/", exact: true, Component: Home },
  { path: "/sign-in", exact: true, Component: SignIn },
  { path: "/verify-user/:token", exact: false, Component: VerifyUser },
  { path: "/reset-password/:token", exact: false, Component: ResetPassword },
];

export const privateRoutes = [
  { path: "/admin", exact: true, Component: Dashboard },
  { path: "/billBoards", exact: true, Component: BillBoardList },
  { path: "/faq", exact: true, Component: BoardFAQList },
  { path: "/notice", exact: true, Component: BoardNoticeList },
  { path: "/exercise", exact: true, Component: exerciseList },
  { path: "/coach", exact: true, Component: coachList },
  { path: "/coachExec", exact: true, Component: coachExecList },
  { path: "/execClip", exact: true, Component: execClipList },
  { path: "/execPlaylist", exact: true, Component: ExecPlaylistList },
  { path: "/users", exact: true, Component: UserList },
  { path: "/batch", exact: true, Component: Batch },
  { path: "/roles", exact: true, Component: RoleList },
];
