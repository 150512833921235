import React from "react";
import { Button, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";

import {
  BATCH_LIST,
  BATCH_VIEW,
  BATCH_RUN,
  BATCH_NEW,
  BATCH_UPDATE,
  BATCH_REMOVE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const [batchRun] = useMutation(BATCH_RUN, {
    onCompleted: (response) => {
      console.log(response);
      if (!response) return;
    },
  });

  const headers = [
    { id: "_id", label: "ID" },
    { id: "title", label: "Title" },
    { id: "thumbNail", label: "ThumbNail" },
    { id: "description", label: "Description" },
  ];

  const form = {
    name: "Batch",
    crud: {
      c: BATCH_NEW,
      r: BATCH_VIEW,
      u: BATCH_UPDATE,
      d: BATCH_REMOVE,
    },
    columns: [
      { id: "title", label: "Title" },
      { id: "thumbNail", label: "ThumbNail" },
      { id: "description", label: "Description" },
    ],
  };

  return (
    <Page
      title={t("Batch")}
      pageTitle={
        <Typography variant="h4">
          {t("Batch")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Button
        variant="outlined"
        onClick={() => batchRun({ variables: { input: { type: "VIDEO" } } })}
      >
        {"Video Upload"}
      </Button>
      <Button
        variant="outlined"
        onClick={() => batchRun({ variables: { input: { type: "IMAGE" } } })}
      >
        {"Image Upload"}
      </Button>
      <Button
        variant="outlined"
        onClick={() => batchRun({ variables: { input: { type: "DATA" } } })}
      >
        {"Data validate"}
      </Button>
      <Table headers={headers} form={form} query={{ batchList: BATCH_LIST }} />
    </Page>
  );
};

export default List;
