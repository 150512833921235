import React, { useContext, useEffect } from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { IHelmet, LayoutType } from "@homexjang/datasources/types";
import { UIContext } from "../context";
import PageHeader from "./PageHeader";
import PageContent from "./PageContent";

interface Props {
  layout?: LayoutType;
  title?: string;
  helmet?: IHelmet;
  headerLeft?: JSX.Element | JSX.Element[];
  headerRight?: JSX.Element | JSX.Element[];
  pageTitle?: string | JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

const Page = ({
  layout,
  title,
  helmet,
  headerLeft,
  headerRight,
  pageTitle,
  children,
}: Props) => {
  const classes = useStyles();
  const uiContext = useContext(UIContext);

  useEffect(() => {
    if (uiContext && uiContext.setValues) {
      uiContext.setValues({ layout, headerLeft, title, helmet });
    }
  }, [title, helmet, headerLeft, layout, uiContext]);

  const PageTitle = () => {
    if (pageTitle) {
      return typeof pageTitle !== "string" ? (
        <React.Fragment>{pageTitle}</React.Fragment>
      ) : (
        <Typography variant="h4">{pageTitle}</Typography>
      );
    } else if (title) {
      return <Typography variant="h4">{title}</Typography>;
    } else {
      return null;
    }
  };

  return (
    <Box className={classes.container}>
      {layout !== "BOX" && (
        <React.Fragment>
          {layout === "FULL_WIDTH" && <Toolbar />}
          <PageHeader left={headerLeft} right={headerRight}>
            {PageTitle()}
          </PageHeader>
        </React.Fragment>
      )}
      <PageContent>{children}</PageContent>
    </Box>
  );
};

export default Page;
