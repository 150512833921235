import { gql } from "@apollo/client";

export const ME = gql`
  query Me {
    me {
      _id
      nickName
      emails {
        address
        verified
      }
      roles {
        _id
      }
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($input: UserInput) {
    signUp(input: $input) {
      _id
      emails {
        address
        verified
      }
      createdAt
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($input: UserInput) {
    signIn(input: $input) {
      _id
      nickName
      emails {
        address
        verified
      }
      roles {
        _id
      }
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VerifyUser($token: String) {
    verifyUser(token: $token) {
      _id
      nickName
      emails {
        address
        verified
      }
      status
      general {
        firstName
        lastName
      }
      tokens
      createdAt
      updatedAt
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String, $password: String) {
    resetPassword(token: $token, password: $password)
  }
`;
