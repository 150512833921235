import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Collapse,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import mainMenu from "../config/menu";
import {
  LayoutType,
  MenuItem,
  IRole,
  MenuDummy,
} from "@homexjang/datasources/types";
import AccountBar from "./AccountBar";
import { LAYOUT } from "../config/constants";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: LAYOUT.menuWidth,
  },
  fullList: {
    width: "auto",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

type Anchor = "top" | "left" | "bottom" | "right";

interface Props {
  roles?: IRole[];
  open: boolean;
  direction: Anchor;
  layout: LayoutType | undefined;
  onProfile: () => void;
  onSignOut: (value: boolean) => void;
  onToggleMenu: (
    anchor: Anchor,
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const filterRoles = (menu: any, roles: IRole[]) => {
  const rootRole =
    -1 < _.findIndex(roles, (doc: IRole) => doc?._id === "ROLE_ROOT");

  if (rootRole) {
    return menu;
  } else {
    if (menu?.roles) {
      for (let i = 0; i < menu?.roles?.length; i++) {
        const role = menu?.roles[i];
        for (let j = 0; j < roles?.length; j++) {
          if (role === roles[j]?._id) {
            return menu;
          }
        }
      }
    }
  }
};

const MyMenu = ({
  roles,
  open,
  direction,
  layout,
  onProfile,
  onSignOut,
  onToggleMenu,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isBeyondMobile = useMediaQuery("(min-width:600px)");

  const [openFolder, setOpenFolder] = useState<number | null>(null);
  // const [selected, setSelected] = useState<number | null>(null);

  const getSelectedIndex = (path: string): number => {
    let selectedIndex = null;
    const menues = mainMenu;
    menues.forEach((doc: MenuDummy, index: number) => {
      const { list } = doc;

      list &&
        list.forEach((item: MenuItem) => {
          const { children } = item;
          if (children) {
            children.forEach((child: MenuItem) => {
              if (path.indexOf(child.path) > -1) {
                selectedIndex = index;
              }
            });
          } else {
            if (path.indexOf(item.path) > -1) {
              selectedIndex = index;
            }
          }
        });
    });
    return selectedIndex;
  };

  useEffect(() => {
    let selectedIndex = null;

    const path = location.pathname;
    selectedIndex = getSelectedIndex(path);
    if (selectedIndex) {
      setOpenFolder(selectedIndex);
    }
  }, [history, location.pathname]);

  const toggleFolder = (j: number) => {
    setOpenFolder(openFolder === j ? null : j);
  };

  const handleProfile = () => {
    // setSelected(null);
    onProfile();
  };

  const handleSignOut = (value: boolean) => {
    if (value) onSignOut(value);
  };

  const list = (anchor: Anchor) => {
    const handleClick = (item: MenuItem | undefined, index: number) => {
      if (!item?.path) return;
      // setSelected(index);
      history.push(item.path);
    };

    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={onToggleMenu(anchor, false)}
        onKeyDown={onToggleMenu(anchor, false)}
      >
        {mainMenu.map((menu, i) => {
          // if (menu.type === 'divider') {
          //   return <Divider key={i} />;
          // }

          return (
            <List key={i} dense disablePadding>
              {filterRoles(menu, roles)?.list?.map(
                (item: MenuItem, j: number) =>
                  item.path ? (
                    <ListItem
                      key={j}
                      dense
                      disableGutters
                      onClick={() => {
                        handleClick(item, i);
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <Icon>{item.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </ListItem>
                  ) : (
                    <div key={j}>
                      <ListItem
                        dense
                        disableGutters
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFolder(i);
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Icon>
                              {openFolder === i ? "folder_open" : "folder"}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText primary={item.title} />
                          {openFolder === i ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={openFolder === i}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding>
                          {item.children?.map((doc, k) => (
                            <ListItem
                              key={k}
                              dense
                              disableGutters
                              onClick={() => {
                                handleClick(doc, i);
                              }}
                            >
                              <ListItemButton
                                selected={
                                  location?.pathname?.indexOf(doc.path) > -1
                                }
                              >
                                <ListItemIcon className={classes.nested}>
                                  <Icon>{doc.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={doc.title} />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  )
              )}
            </List>
          );
        })}
      </div>
    );
  };

  return (
    <Drawer
      anchor={direction}
      open={open}
      variant={LAYOUT.fixedMenu && isBeyondMobile ? "permanent" : "temporary"}
      onClose={onToggleMenu(direction, false)}
    >
      {layout === "FULL_WIDTH" && isBeyondMobile && <Toolbar />}
      {!isBeyondMobile && (
        <React.Fragment>
          <AccountBar onProfile={handleProfile} onSignOut={handleSignOut} />
          <Divider />
        </React.Fragment>
      )}
      {list(direction)}
    </Drawer>
  );
};

export default MyMenu;
