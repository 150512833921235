import React from "react";

import { t } from "../../lib";
import { Page } from "../../layout";

const Dashboard = () => {
  return (
    <Page
      layout="CUSTOM"
      title=""
      helmet={{ title: t("Home"), description: "TS-REACT Home" }}
    >
      <div
        style={{
          width: "100%",
          height: "100px",
          alignItems: "center",
          verticalAlign: "center",
        }}
      >
        <img src="images/logo/app.png" alt="appicon" height={66} />
        <img
          src="images/logo/hxj.png"
          alt="logo"
          height={33}
          style={{ margin: "16px" }}
        />
      </div>
      <div style={{ alignItems: "center", verticalAlign: "center" }}>
        <br />
        <img
          src="images/appstore/1.jpg"
          alt="appimage"
          height={500}
          style={{ margin: "16px" }}
        />
        <img
          src="images/appstore/3.jpg"
          alt="appimage"
          height={500}
          style={{ margin: "16px" }}
        />
      </div>
      <div>SUPPORT - {"admin@homexjang.com"}</div>
    </Page>
  );
};

export default Dashboard;
