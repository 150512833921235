import { gql } from '@apollo/client';

export const MY_SYSTEM_LIST = gql`
  query MySystemList {
    mySystemList {
      _id
      dataPublic
      dataPrivate
      createdAt
      updatedAt
    }
  }
`;

export const MY_SYSTEM_VIEW = gql`
  query MySystemView($id: ID) {
    mySystemView(id: $id) {
      _id
      dataPublic
    }
  }
`;
