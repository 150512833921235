import { gql } from "@apollo/client";

const COACHEXEC_LIST = gql`
  query CoachExecList($searchText: String, $skip: Int, $limit: Int) {
    coachExecList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        coach {
          _id
        }
        exercise {
          _id
        }
        use
      }
    }
  }
`;

const COACHEXEC_VIEW = gql`
  query CoachExecView($id: ID) {
    coachExecView(id: $id) {
      _id
      coach {
        _id
      }
      exercise {
        _id
      }
      use
    }
  }
`;

const COACHEXEC_CREATE = gql`
  mutation CoachExecCreate($input: coachExecInput) {
    coachExecCreate(input: $input) {
      _id
      type
      title
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const COACHEXEC_UPDATE = gql`
  mutation CoachExecUpdate($id: ID, $input: coachExecInput) {
    coachExecUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const COACHEXEC_DELETE = gql`
  mutation CoachExecDelete($id: ID) {
    coachExecDelete(id: $id)
  }
`;

export {
  COACHEXEC_LIST,
  COACHEXEC_VIEW,
  COACHEXEC_CREATE,
  COACHEXEC_UPDATE,
  COACHEXEC_DELETE,
};
