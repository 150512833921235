import { gql } from "@apollo/client";

const BILLBOARD_LIST = gql`
  query BillBoardList($searchText: String, $skip: Int, $limit: Int) {
    billBoardList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        type
        title
        thumbNail
        link
        use
        description
        createdAt
        updatedAt
        updatedUser {
          _id
        }
      }
    }
  }
`;

const BILLBOARD_VIEW = gql`
  query BillBoardView($id: ID) {
    billBoardView(id: $id) {
      _id
      type
      title
      thumbNail
      link
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const BILLBOARD_CREATE = gql`
  mutation BillBoardCreate($input: BillBoardInput) {
    billBoardCreate(input: $input) {
      _id
      type
      title
      thumbNail
      link
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const BILLBOARD_UPDATE = gql`
  mutation BillBoardUpdate($id: ID, $input: BillBoardInput) {
    billBoardUpdate(id: $id, input: $input) {
      _id
      type
      title
      thumbNail
      link
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const BILLBOARD_DELETE = gql`
  mutation BillBoardDelete($id: ID) {
    billBoardDelete(id: $id)
  }
`;

export {
  BILLBOARD_LIST,
  BILLBOARD_VIEW,
  BILLBOARD_CREATE,
  BILLBOARD_UPDATE,
  BILLBOARD_DELETE,
};
