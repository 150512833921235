import React from "react";
import { Typography } from "@mui/material";

import {
  EXECCLIP_LIST,
  EXECCLIP_VIEW,
  EXECCLIP_CREATE,
  EXECCLIP_UPDATE,
  EXECCLIP_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "_id", label: "ID" },
    { id: "coach._id", label: "Name" },
    { id: "exercise._id", label: "Exercise" },
    { id: "time", label: "Time" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "execClip",
    crud: {
      c: EXECCLIP_CREATE,
      r: EXECCLIP_VIEW,
      u: EXECCLIP_UPDATE,
      d: EXECCLIP_DELETE,
    },
    columns: [
      { id: "_id", label: "ID" },
      { id: "coachExec._id", label: "Name" },
      { id: "exercise._id", label: "Exercise" },
      { id: "repeatUnit", label: "Unit" },
      { id: "repeatCount", label: "Count" },
      { id: "time", label: "Time" },
      { id: "use", label: "Use", format: "Boolean" },
    ],
    excel: true,
  };

  return (
    <Page
      title={t("ExecClip")}
      pageTitle={
        <Typography variant="h4">
          {t("ExecClip")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ execClipList: EXECCLIP_LIST }}
      />
    </Page>
  );
};

export default List;
