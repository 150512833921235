import { gql } from "@apollo/client";

const CHECK_UPLOAD = gql`
  query CheckUpload($id: ID, $name: String, $columns: [ColumnInput]) {
    checkUpload(id: $id, name: $name, columns: $columns) {
      result {
        _id
        _status
        _error
      }
      add
      remove
      error
    }
  }
`;

const APPLY_UPLOAD = gql`
  query ApplyUpload(
    $id: ID
    $name: String
    $columns: [ColumnInput]
    $apply: Boolean
  ) {
    applyUpload(id: $id, name: $name, columns: $columns, apply: $apply) {
      result
    }
  }
`;

export { CHECK_UPLOAD, APPLY_UPLOAD };
