import { gql } from "@apollo/client";

const EXECCLIP_LIST = gql`
  query ExecClipList(
    $searchConditions: [SearchConditionInput]
    $skip: Int
    $limit: Int
  ) {
    execClipList(
      searchConditions: $searchConditions
      skip: $skip
      limit: $limit
    ) {
      _id
      totalCount
      list {
        _id
        coachExec {
          _id
        }
        repeatUnit
        repeatCount
        use
        createdAt
      }
    }
  }
`;

const EXECCLIP_VIEW = gql`
  query ExecClipView($id: ID) {
    execClipView(id: $id) {
      _id
      coachExec {
        _id
      }
      repeatUnit
      repeatCount
      use
      createdAt
    }
  }
`;

const EXECCLIP_CREATE = gql`
  mutation ExecClipCreate($input: execClipInput) {
    execClipCreate(input: $input) {
      _id
      type
      title
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const EXECCLIP_UPDATE = gql`
  mutation ExecClipUpdate($id: ID, $input: execClipInput) {
    execClipUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const EXECCLIP_DELETE = gql`
  mutation ExecClipDelete($id: ID) {
    execClipDelete(id: $id)
  }
`;

export {
  EXECCLIP_LIST,
  EXECCLIP_VIEW,
  EXECCLIP_CREATE,
  EXECCLIP_UPDATE,
  EXECCLIP_DELETE,
};
