import { gql } from "@apollo/client";

const EXERCISE_LIST = gql`
  query ExerciseList($searchText: String, $skip: Int, $limit: Int) {
    exerciseList(searchText: $searchText, skip: $skip, limit: $limit) {
      _id
      totalCount
      list {
        _id
        type
        level
        partA
        partB
        partC
        nameKor
        nameEng
        use
        description
        createdAt
        updatedAt
        updatedUser {
          _id
        }
      }
    }
  }
`;

const EXERCISE_VIEW = gql`
  query ExerciseView($id: ID) {
    exerciseView(id: $id) {
      _id
      type
      repeat
      level
      partA
      partB
      partC
      nameKor
      nameEng
      tags
      thumbNail
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const EXERCISE_CREATE = gql`
  mutation ExerciseCreate($input: exerciseInput) {
    exerciseCreate(input: $input) {
      _id
      type
      title
      use
      description
      createdAt
      updatedAt
      updatedUser {
        _id
      }
    }
  }
`;

const EXERCISE_UPDATE = gql`
  mutation ExerciseUpdate($id: ID, $input: exerciseInput) {
    exerciseUpdate(id: $id, input: $input) {
      _id
    }
  }
`;

const EXERCISE_DELETE = gql`
  mutation ExerciseDelete($id: ID) {
    exerciseDelete(id: $id)
  }
`;

export {
  EXERCISE_LIST,
  EXERCISE_VIEW,
  EXERCISE_CREATE,
  EXERCISE_UPDATE,
  EXERCISE_DELETE,
};
