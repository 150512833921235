import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  TextField,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  Grid,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Modal } from "../components";

import { IListItem } from "@homexjang/datasources/types";
import { t } from "../lib";

interface Props {
  data?: IListItem[];
  disablePadding?: boolean;
  onChange?: (name: string, value: string) => void;
  onClick?: () => void;
}

interface PopupItem {
  name?: string;
  desc?: string;
  imgs?: string[];
}

interface GroupItem {
  name?: string;
  items?: PopupItem[][];
}

interface Popup {
  id?: string;
  name?: string;
  items?: PopupItem[];
  group?: GroupItem[];
  imgs?: string[];
  path?: string;
  maxWidth?: "sm" | "md" | "lg" | "xl" | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItemButton: {
    paddingRight: 0,
  },
  listItemRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const MyList = ({ data, disablePadding, onChange }: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [popup, setPopup] = useState<Popup>();
  const [selectedValue, setSelectedValue] = useState<string>();

  if (!data) return null;

  const handleRadioClick = (value: string) => {
    setSelectedValue(value);
    onChange(popup.id, value);
    setOpen(false);
  };

  return (
    <List>
      {data.map((doc: IListItem) => {
        if (doc.popup?.group) {
          doc.popup.items = [];

          doc.popup.group.forEach((g: any) => {
            g.items.forEach((items: any) => {
              doc.popup.items = [...doc.popup.items, ...items];
            });
          });
        }

        return (
          <ListItem
            key={doc.name}
            dense
            disableGutters
            divider
            disablePadding={disablePadding}
          >
            <ListItemButton
              className={classes.listItemButton}
              // disabled={!doc.onClick}
              onClick={
                doc.readonly
                  ? null
                  : () => {
                      doc.onClick && doc.onClick(doc);
                    }
              }
            >
              {doc.check && <Checkbox size={"small"} checked={doc.checked} />}
              <ListItemText>{doc.name}</ListItemText>
              <Box className={classes.listItemRight}>
                {doc.input ? (
                  <TextField
                    name={doc.id}
                    size="small"
                    value={doc.value}
                    onChange={
                      doc.readonly
                        ? null
                        : (e) => {
                            onChange(e.target.name, e.target.value);
                          }
                    }
                  />
                ) : doc.select ? (
                  doc.multi ? (
                    <Select
                      size="small"
                      name={doc.id}
                      multiple
                      value={doc.value ? doc.value : []}
                      onChange={
                        doc.readonly
                          ? null
                          : (e) => {
                              onChange(e.target.name, e.target.value);
                            }
                      }
                      renderValue={(selected) => {
                        const labels = selected.map((v: string) => {
                          const item = doc?.select?.find(
                            (v2: any) => v2._id === v
                          );
                          return item?.name;
                        });
                        return labels.join(", ");
                      }}
                    >
                      {doc.select?.map(
                        (item: { _id: string; name: string }) => (
                          <MenuItem
                            style={{ padding: 0 }}
                            key={item._id}
                            value={item._id}
                            dense={true}
                          >
                            <Checkbox
                              checked={doc?.value?.indexOf(item._id) > -1}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  ) : (
                    <TextField
                      name={doc.id}
                      select
                      size="small"
                      value={doc.value}
                      onChange={
                        doc.readonly
                          ? null
                          : (e) => {
                              onChange(e.target.name, e.target.value);
                            }
                      }
                    >
                      {doc.select?.map(
                        (item: { _id: string; name: string }) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  )
                ) : doc.popup ? (
                  <Select
                    size="small"
                    value={doc.value ? doc.value : []}
                    onClick={async (e) => {
                      e.preventDefault();
                      await setSelectedValue(doc.value);
                      await setPopup({ ...doc.popup, id: doc.id });
                      await setOpen(true);
                    }}
                    inputProps={{ readOnly: true }}
                  >
                    {doc.popup.items.map(
                      (item: PopupItem) =>
                        item.name && (
                          <MenuItem
                            key={item.name}
                            value={item.name}
                            dense={true}
                          >
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        )
                    )}
                  </Select>
                ) : (
                  <Typography>{doc.value || ""}</Typography>
                )}
                {doc.unit && <Typography>{` ${doc.unit}` || ""}</Typography>}
              </Box>
            </ListItemButton>
          </ListItem>
        );
      })}

      <Modal
        open={open}
        title={`${popup?.name}`}
        Actions={
          <React.Fragment>
            <Button onClick={() => setOpen(false)}>{t("Cancel")}</Button>
          </React.Fragment>
        }
        onClose={() => setOpen(false)}
        maxWidth={popup?.maxWidth}
      >
        {popup?.group ? (
          <TableContainer>
            <Table>
              <TableBody>
                {popup?.group.map((g: GroupItem) => (
                  <>
                    {g.items.map((gitems: PopupItem[], gi) => (
                      <TableRow key={gi}>
                        {gitems.map((item: PopupItem) => (
                          <>
                            <TableCell
                              key={item.name}
                              onClick={() => handleRadioClick(item.name)}
                            >
                              <Radio checked={selectedValue === item.name} />
                            </TableCell>
                            <TableCell
                              key={item.name}
                              onClick={() => handleRadioClick(item.name)}
                            >
                              <Typography>{item.name}</Typography>
                              {item?.imgs && (
                                <Grid container spacing={2}>
                                  {item.imgs.map((img) => (
                                    <Grid key={img} item xs={6}>
                                      <img
                                        draggable={"false"}
                                        style={{ height: `70px` }}
                                        src={`${popup.path}/${img}`}
                                        alt={item.name}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : popup?.items ? (
          <TableContainer>
            <Table>
              <TableBody>
                {popup.items?.map((item: PopupItem, idx) => (
                  <TableRow key={item.name}>
                    <TableCell
                      onClick={() => {
                        handleRadioClick(item.name);
                      }}
                    >
                      <Radio
                        name={popup?.id}
                        value={item.name}
                        checked={selectedValue === item.name}
                      />
                    </TableCell>
                    <TableCell
                      width={"120px"}
                      onClick={() => {
                        handleRadioClick(item.name);
                      }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      width={"600px"}
                      colSpan={item.imgs ? 1 : 2}
                      onClick={() => {
                        handleRadioClick(item.name);
                      }}
                    >
                      {item.desc}
                    </TableCell>
                    {item.imgs && (
                      <TableCell
                        onClick={() => {
                          handleRadioClick(item.name);
                        }}
                      >
                        {item.imgs.map((img) => (
                          <img
                            draggable={"false"}
                            style={{ height: `100px` }}
                            key={img}
                            src={`${popup.path}/${img}`}
                            alt={item.name}
                          />
                        ))}
                      </TableCell>
                    )}

                    {popup.imgs && idx === 0 && (
                      <TableCell rowSpan={popup.items.length}>
                        <div>
                          {popup.imgs.map((img: string) => (
                            <img
                              draggable={"false"}
                              style={{
                                height: `${popup.items.length * 100}px`,
                              }}
                              key={img}
                              src={`${popup.path}/${img}`}
                              alt={item.name}
                            />
                          ))}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Modal>
    </List>
  );
};

export default MyList;
