import { Breakpoint } from "@mui/system";

import {
  IListArgs,
  ISessionContext,
  IUIContext,
  IUser,
} from "@homexjang/datasources/types";
import { t } from "../lib/i18n";

// 앱 일반
export const APP = {
  name: t("AppName"),
  version: "0.1.0",
};

const HOST = process.env.REACT_APP_HOST;
const PORT = process.env.REACT_APP_PORT;
const SSL = process.env.REACT_APP_SSL;

// 서버 접속 주소
export const SERVER = {
  url: `${SSL === "true" ? "https" : "http"}://${HOST}${
    PORT ? ":" + PORT : ""
  }`,
  path: "graphql",
};

const LAYOUT_PAPER_WIDTH: Breakpoint = "md";
const LAYOUT_BOX_WIDTH: Breakpoint = "sm";

export const LAYOUT = {
  paperWidth: LAYOUT_PAPER_WIDTH, // LAYOUT PAPER의 화면 너비
  boxWidth: LAYOUT_BOX_WIDTH, // LAYOUT BOX의 화면 너비
  headerRadius: 10,
  menuWidth: 240,
  fixedMenu: true,
};

// UI_CONTEXT의 초기 설정값
export const UI_CONTEXT_DEFAULT: IUIContext = {
  layout: "FULL_WIDTH",
  title: "",
  helmet: {
    title: "",
    description: "",
  },
};

// 회원 세션 정보의 초기 설정값
export const SESSION_CONTEXT_DEFAULT: ISessionContext = {
  account: undefined,
  isAuthenticated: () => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn: (user: IUser) => {
    // do nothing
  },
  signOut: () => {
    // do nothing
  },
  refresh: () => {
    // do nothing
  },
};

// Local Storage 저장용 키값 정의
export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  SESSION: "SESSION",
};

export const NOTI_STACK_DEPTH = 3;

export const LIST_ARGS_DEFAULT: IListArgs = {
  skip: 0,
  limit: 10,
};

export const STORAGE_KEY_AI_DATA_INSPECTION_STATUS =
  "AI_DATA_INSPECTION_STATUS";
