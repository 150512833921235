import React from "react";
import { Typography } from "@mui/material";

import {
  EXECPLAYLIST_LIST,
  EXECPLAYLIST_VIEW,
  EXECPLAYLIST_CREATE,
  EXECPLAYLIST_UPDATE,
  EXECPLAYLIST_DELETE,
} from "../../graphql";
import { Table } from "../../components";
import { t } from "../../lib";
import { Page } from "../../layout";

const List = () => {
  const headers = [
    { id: "coach._id", label: "Name" },
    { id: "name", label: "Name" },
    { id: "_id", label: "ID" },
    { id: "_id", label: "ID" },
    { id: "_id", label: "ID" },

    { id: "exercise._id", label: "Nick" },
    { id: "use", label: "Use" },
  ];

  const form = {
    name: "execPlaylist",
    crud: {
      c: EXECPLAYLIST_CREATE,
      r: EXECPLAYLIST_VIEW,
      u: EXECPLAYLIST_UPDATE,
      d: EXECPLAYLIST_DELETE,
    },
    columns: [
      { id: "_id", label: "ID" },
      { id: "pattern", label: "Pattern" },
      { id: "part", label: "Part" },
      { id: "tool", label: "Tool" },
      { id: "type", label: "Type" },
      { id: "name", label: "Name" },
      { id: "coach._id", label: "CoachName" },
      { id: "items", label: "CoachName", format: "Array" },
      { id: "set", label: "Set" },
      { id: "min", label: "Min" },
      { id: "max", label: "Max" },
      { id: "partA", label: "PartA" },
      { id: "partB", label: "PartB" },
      { id: "partC", label: "PartC" },
      { id: "exercise._id", label: "ExerciseName" },
      { id: "use", label: "Use", format: "Boolean" },
    ],
    excel: true,
  };

  return (
    <Page
      title={t("ExecPlaylist")}
      pageTitle={
        <Typography variant="h4">
          {t("ExecPlaylist")} <small>{t("List")}</small>
        </Typography>
      }
    >
      <Table
        headers={headers}
        form={form}
        query={{ execPlaylistList: EXECPLAYLIST_LIST }}
      />
    </Page>
  );
};

export default List;
